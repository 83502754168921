
.list-group-horizontal.list-group-sm {
  a {
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

.progress-label {
  float: left;
  margin-right: 1em;
}

.distro {
  display: none;
}

// unused
.label-list-inline>li {
  padding-left: 0px;
  padding-right: 0px;
  line-height: 1.6;
}

.label-as-badge {
    border-radius: 1em;
}

.glyphicon-none:before {
    content: "\2122";
    color: transparent !important;
}

table.table a.label {
  text-decoration: none;
}

.top-buffer { margin-top:10px; }

div.vcenter {
  display: inline-block;
  vertical-align: middle;
  float: none;
}

.truncate {
    position: relative;
}
.truncate .content {
    position: absolute;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.truncate .spacer {
    height: 0;
    overflow: hidden;
}


a.inactive {
  color: #999999;
  text-decoration: none;
}

// Used in dependencies to denote no further 2nd order dependencies
a.endoftree {
  color: #5e00c7;
  text-decoration: none;
}

table.table a .glyphicon {
  text-decoration: none;
}

table.instance-switch {
  margin-bottom: 0px;
}

table.instance-switch tbody tr td {
  padding: 0px;
}

table.table {
  margin-bottom: 0px;
}

div.panel-heading {
  padding-left: 10px;
  padding-right: 10px;
}

div.panel-body {
  padding: 10px;
  overflow-x: auto;
}

div pre {
  overflow-x: auto;
  word-break: normal;
  word-wrap: normal;
}

div pre {
  word-wrap: normal;
}

div pre code {
  white-space: inherit;
}

div code {
  white-space: nowrap;
}

label.control-label {
  font-weight: bold;
}

table.table thead a:not(.btn) {
  text-decoration: none;
}

.selected-sort-label {
  color: #ff851b;
}

.unselected-sort-label {
  color: #555555;
}

.nav-tabs>li.better-tabs.active>a {
  background-color: #ffffff;
}

.nav-tabs>li.better-tabs>a {
  background-color: #eeeeee;
}

.list-group-horizontal.list-group-justified {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.list-group-horizontal>a.list-group-item {
  display: table-cell;
  overflow-x: hidden;
}

.list-group-horizontal>a.list-group-item>span.glyphicon {
  margin: 0px;
}

.rendered-markdown {
  margin-left: 30px;
  margin-right: 30px;
}

/*div#toc {*/
  /*padding-left: 0px;*/
  /*padding-right: 0px;*/
  /*padding-bottom: 5px;*/
/*}*/

div#toc a {
  font-weight: bold;
}

.alert-warning h3 {
  color: #FFF;
}

.pkg-label {
  margin-right: 2px;
  display: inline-block;
}


/*h5 a:hover {*/
  /*text-decoration: none;*/
/*}*/


/**
 * Reset some basic elements
 */
/*body {*/
    /*margin: 0;*/
    /*padding: 0;*/
/*}*/

/*body, h1, h2, h3, h4, h5, h6,*/
/*p, blockquote, pre, hr,*/
/*dl, dd, ol, ul, figure {*/
    /*margin: 0;*/
    /*padding: 0;*/
/*}*/



/**
 * Basic styling
 */
/*
 *body {
 *    font-family: $base-font-family;
 *    font-size: $base-font-size;
 *    line-height: $base-line-height;
 *    font-weight: 300;
 *    color: $text-color;
 *    background-color: $background-color;
 *    -webkit-text-size-adjust: 100%;
 *}
 */



/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1, h2, h3, h4, h5, h6,
p, blockquote, pre,
ul, ol, dl, figure,
%vertical-rhythm {
    margin-bottom: $spacing-unit / 2;
}



/**
 * Images
 */
img {
    max-width: 100%;
    /*vertical-align: middle;*/
}



/**
 * Figures
 */
/*figure > img {*/
    /*display: block;*/
/*}*/

/*figcaption {*/
    /*font-size: $small-font-size;*/
/*}*/



/**
 * Lists
 */
/*ul, ol {*/
    /*margin-left: $spacing-unit;*/
/*}*/

/*li {*/
    /*> ul,*/
    /*> ol {*/
         /*margin-bottom: 0;*/
    /*}*/
/*}*/



/**
 * Headings
 */
/*h1, h2, h3, h4, h5, h6 {*/
    /*font-weight: 300;*/
/*}*/


/**
 * Links
 */
/*a {*/
    /*color: $brand-color;*/
    /*text-decoration: none;*/

    /*&:visited {*/
        /*color: darken($brand-color, 15%);*/
    /*}*/

    /*&:hover {*/
        /*color: $text-color;*/
        /*text-decoration: underline;*/
    /*}*/
/*}*/



/**
 * Blockquotes
 */
/*
blockquote {
    color: $grey-color;
    border-left: 4px solid $grey-color-light;
    padding-left: $spacing-unit / 2;
    font-size: 18px;
    letter-spacing: -1px;
    font-style: italic;

    > :last-child {
        margin-bottom: 0;
    }
}
*/

code {
  color: #666666;
  background-color: #ECECEC;
}


/**
 * Code formatting
 */
/*pre,*/
/*code {*/
    /*font-size: 15px;*/
    /*border: 1px solid $grey-color-light;*/
    /*border-radius: 3px;*/
    /*background-color: #eef;*/
/*}*/

/*code {*/
    /*padding: 1px 5px;*/
/*}*/

/*pre {*/
    /*padding: 8px 12px;*/
    /*overflow-x: scroll;*/

    /*> code {*/
        /*border: 0;*/
        /*padding-right: 0;*/
        /*padding-left: 0;*/
    /*}*/
/*}*/



/**
 * Wrapper
 */
.wrapper {
    max-width: -webkit-calc(1920px - (0px * 2));
    max-width:         calc(1920px - (0px * 2));
    margin-right: auto;
    margin-left: auto;
    padding-right: 0px;
    padding-left: 0px;
    @extend %clearfix;

    @include media-query($on-laptop) {
        max-width: -webkit-calc(1920px - (0px));
        max-width:         calc(1920px - (0px));
        padding-right: 0px / 2;
        padding-left: 0px / 2;
    }
}



/**
 * Clearfix
 */
%clearfix {

    &:after {
        content: "";
        display: table;
        clear: both;
    }
}



/**
 * Icons
 */
.icon {

    > svg {
        display: inline-block;
        width: 16px;
        height: 16px;
        vertical-align: middle;

        path {
            fill: $grey-color;
        }
    }
}

.list-group-horizontal .list-group-item {
  display: inline-block;
}
.list-group-horizontal .list-group-item {
  margin-bottom: 0;
  margin-left:-4px;
  margin-right: 0;
}
.list-group-horizontal .list-group-item:first-child {
  border-top-right-radius:0;
  border-bottom-left-radius:4px;
}
.list-group-horizontal .list-group-item:last-child {
  border-top-right-radius:4px;
  border-bottom-left-radius:0;
}

.list-group.list-group-root {
  padding: 0;
  margin-bottom: 0;
}
.list-group.list-group-root .list-group {
  margin-bottom: 0;
}
.list-group.list-group-root .list-group-item {
  border-radius: 0;
  border-width: 1px 0 0 0;
}
.list-group.list-group-root > .list-group-item:first-child {
  border-top-width: 0;
}
.list-group.list-group-root > .list-group > .list-group-item {
  padding-left: 30px;
}
.list-group.list-group-root > .list-group > .list-group > .list-group-item {
  padding-left: 45px;
}
.list-group.list-group-root > .list-group > .list-group > .list-group > .list-group-item {
    padding-left: 60px;
}

.btn-primary.active:hover,
.btn-primary.active:focus {
  background-color: #106a8c;
}

div.question-description-collapsed {
  height: 70px;
  overflow: hidden;
  cursor: pointer;
}
a.question-description-toggle {
  cursor: pointer;
}
a.question-link {
  float: right;
}

/* Collapsable filter and search. */
.collapsed .collapsing-icon::before {
  // glyphicon-chevron-down
  content: "\e114";
}
.collapsing-icon::before {
  // glyphicon-chevron-up
  content: "\e113";
}
.collapsing-header:hover {
  text-decoration: underline;
}

/* checkbox in search_package */
#search-enable-box {
  border: thin solid gray;
  border-radius: 2px;
  width: 16px;
  height: 16px;
  line-height: 16px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  margin-right: 4px;
}
